"use strict"

import { swupTransitions } from './scripts/swup'
import { utils } from './scripts/utils';
import { Cursor4 } from './scripts/cursors/cursor4';

export const setCursor = () => {
  new Cursor4(4);
}

window.addEventListener('load', async () => {
  await utils();
  swupTransitions();
});
